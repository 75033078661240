import React, { FormEventHandler } from "react";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Header from "../components/Header";
import MarkdownContent from "../components/MarkdownContent";

const submitHandler: FormEventHandler<HTMLFormElement> = (e) => {
  e.preventDefault();
  const form = e.target as HTMLFormElement;
  const query = form.elements["query"].value;
  const url = new URL("https://duckduckgo.com/");
  url.searchParams.append("q", `site:${location.origin} ${query}`);
  location.href = url.href;
};

const NotFoundPage: React.FC<{}> = () => {
  return (
    <Layout>
      <Head title="404: Not found" />
      <Header title="Not found" />
      <MarkdownContent>
        <p>Whatever you are looking for, it's not here.</p>
        <form onSubmit={submitHandler} action="#">
          <fieldset>
            <legend>Maybe try searching.</legend>
            <input name="query" />
            <button type="submit">Search</button>
          </fieldset>
        </form>
      </MarkdownContent>
    </Layout>
  );
};

export default NotFoundPage;
